import { createSlice } from '@reduxjs/toolkit';

import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  classList: [],
  currentClass: null,
};

const slice = createSlice({
  name: 'classes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getClassList.fulfilled, (state, action) => {
        state.classList = action.payload?.data;
      })
      .addCase(thunks.getClassroom.fulfilled, (state, action) => {
        state.currentClass = action.payload?.data;
      });
  },
});

const classes = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { classes };
export default slice.reducer;
